<template>
  <section>
    <b-modal
      header-bg-variant="outline-success"
      style="border-radius: 10px;"
      size="md"
      @ok="pressOk"
      id="modal-1"
      centered
      title="Cadastrar bairro/distrito"
    >
      <gmap-autocomplete
        placeholder="Digite o bairro e cidade"
        :select-first-on-enter="true"
        @input="district = $event.target.value"
        id="input-6"
        class="form-control"
        :value="district"
        @place_changed="setPlaceDistrict"
      ></gmap-autocomplete>
    </b-modal>
    <h4>Dados do paciente</h4>
    <form class="was-validated" @submit="save">
      <div class="form-row">
        <div class="col-md-5 mb-3">
          <label for="validationCustom01">Nome</label>
          <input
            type="text"
            class="form-control"
            id="validationCustom01"
            placeholder="Nome"
            v-model="model.name"
            required
          />
          <div class="valid-feedback">Deu bom!</div>
        </div>
        <div class="col-md-3 mb-3">
          <label for="validationCustom02">Data de nascimento</label>
          <input
            type="date"
            class="form-control"
            id="validationCustom02"
            placeholder="Nome"
            v-model="model.birth"
            required
          />
          <div class="valid-feedback">Looks good!</div>
        </div>
        <div class="col-md-3 mb-3">
          <label for="validationCustom03">Contato</label>
          <input
            type="number"
            class="form-control"
            id="validationCustom03"
            placeholder="Contato"
            v-model="model.contact"
            required
          />
          <div class="valid-feedback">Looks good!</div>
        </div>
        <div class="col-md-1 mb-3">
          <label for="validationCustom04">Sexo</label>
          <select
            v-model="model.gender"
            class="custom-select"
            id="validationCustom04"
            required
          >
            <option value="M">M</option>
            <option value="F">F</option>
          </select>
          <div class="valid-feedback">Looks good!</div>
        </div>
      </div>
      <div class="form-row">
        <div class="col-md-3 mb-3">
          <label for="validationCustom05">Município do caso</label>
          <select
            v-model="model.city_id"
            class="custom-select"
            id="validationCustom04"
            required
          >
            <option
              v-for="(item, index) in cities"
              :key="index"
              :value="item.id"
              >{{ item.name }}</option
            >
          </select>
          <div class="valid-feedback">Looks good!</div>
        </div>
        <div class="col-md-3 mb-3">
          <label for="validationCustom06">Bairro/Distrito do caso</label>
          <select
            v-model="model.district_id"
            class="custom-select"
            id="validationCustom04"
            required
          >
            <option
              v-for="(item, index) in districts"
              :key="index"
              :value="item.id"
              >{{ item.name }}</option
            >
          </select>
          <span
            v-if="model.district_id !== undefined"
            v-b-modal.modal-1
            class="mt-2 btn btn-outline-success"
            style="border: 1px solid #2ecc71;border-radius: 5px"
            >Adicionar Bairro</span
          >
        </div>
        <div class="col-md-6 mb-3">
          <label for="validationCustom08">Endereço</label>
          <gmap-autocomplete
            ref="autocomplete"
            :disabled="
              model.district_id === undefined || model.city_id === undefined
            "
            :select-first-on-enter="true"
            @input="autocomplete = $event.target.value"
            id="validationCustom08"
            class="form-control"
            :value="autocomplete"
            placeholder="Ex.: Rua José de Alencar, 150"
            @blur="setPlace"
            @place_changed="setPlace"
            required
          ></gmap-autocomplete>
        </div>
      </div>
      <hr />
      <h4>Dados do caso</h4>
      <div class="form-row">
        <div class="col-md-3 mb-3">
          <label for="validationCustom03">Data da notificação</label>
          <input
            name="data"
            type="date"
            class="form-control"
            id="validationCustom03"
            v-model="model.created_at"
            required
          />
          <div class="valid-feedback">Looks good!</div>
        </div>
        <div class="col-md-3 mb-3">
          <label for="validationCustom06">Unidade notificadora</label>
          <select
            v-model="model.reporting_unity"
            class="custom-select"
            id="validationCustom04"
            required
          >
            <option
              v-for="(item, index) in reporting_unity"
              :key="index"
              :value="item.value"
              >{{ item.label }}</option
            >
          </select>
        </div>
        <div class="col-md-3 mb-3">
          <label for="validationCustom06">Situação do caso</label>
          <select
            v-model="model.incident_type"
            class="custom-select"
            id="validationCustom04"
            required
          >
            <option
              v-for="(item, index) in incident_type"
              :key="index"
              :value="item.value"
              >{{ item.label }}</option
            >
          </select>
        </div>
        <div class="col-md-1 mb-3">
          <label for="validationCustom06">Gestante?</label>
          <select
            v-model="model.pregnant"
            class="custom-select"
            id="validationCustom04"
            required
          >
            <option
              v-for="(item, index) in pregnant"
              :key="index"
              :value="item.value"
              >{{ item.label }}</option
            >
          </select>
        </div>
        <div class="col-md-2 mb-3">
          <label for="validationCustom06">Situação do paciente</label>
          <select
            v-model="model.interned"
            class="custom-select"
            id="validationCustom04"
            required
          >
            <option
              v-for="(item, index) in interned"
              :key="index"
              :value="item"
              >{{ item }}</option
            >
          </select>
        </div>
      </div>
      <div class="form-row">
        <div class="col-md-4 mb-3">
          <label for="validationCustom03">Data da internação</label>
          <input
            name="data"
            type="date"
            :disabled="model.interned !== 'Internado'"
            class="form-control"
            id="validationCustom03"
            v-model="model.interned_at"
            required
          />
          <div class="valid-feedback">Looks good!</div>
        </div>
        <div class="col-md-4 mb-3">
          <label for="validationCustom03">Data da alta da internação</label>
          <input
            name="data"
            type="date"
            :disabled="model.interned !== 'Internado'"
            class="form-control"
            id="validationCustom03"
            v-model="model.discharged_at"
            required
          />
          <div class="valid-feedback">Looks good!</div>
        </div>
        <div class="col-md-4 mb-3">
          <label for="validationCustom03">Data dos primeiros sintomas</label>
          <input
            name="data"
            type="date"
            class="form-control"
            id="validationCustom03"
            v-model="model.onset_at"
            required
          />
          <div class="valid-feedback">Looks good!</div>
        </div>
      </div>
      <div class="form-row">
        <div class="col-md-6 mb-3">
          <label for="validationCustom03">Sintomas</label>
          <multiselect
            id="select-1"
            select-label="Selecionar"
            deselect-label="Remover"
            selected-label="Selecionado"
            placeholder="Selecione os sintomas"
            v-model="model.symptoms"
            :multiple="true"
            :close-on-select="false"
            :options="symptoms"
            label="name"
            track-by="id"
          ></multiselect>
        </div>
        <div class="col-md-6 mb-3">
          <label for="validationCustom03">Morbidades prévias</label>
          <multiselect
            id="select-2"
            select-label="Selecionar"
            deselect-label="Remover"
            selected-label="Selecionado"
            placeholder="Selecione as comorbidades prévias"
            v-model="model.morbities"
            :multiple="true"
            :close-on-select="false"
            :options="morbities"
            label="name"
            track-by="id"
          ></multiselect>
        </div>
      </div>
      <div class="form-row">
        <div class="col-md-12 mb-3">
          <label for="validationCustom03">Observações</label>
          <textarea
            name="observacoes"
            type="text"
            class="form-control"
            id="validationCustom03"
            v-model="model.observations"
          />
          <div class="valid-feedback">Looks good!</div>
        </div>
      </div>
      <hr />
      <h4>Dados do exame</h4>
      <div class="form-row">
        <div class="col-md-1 mb-3">
          <label for="validationCustom03">Realizou exames?</label>
          <select
            v-model="model.examined"
            class="custom-select"
            id="validationCustom04"
            required
          >
            <option :value="true">Sim</option>
            <option :value="false">Não</option>
          </select>
        </div>
        <div class="col-md-3 mb-3">
          <label for="validationCustom03">Setor da clínica</label>
          <select
            :disabled="!model.examined"
            v-model="model.public_clinic"
            class="custom-select"
            id="validationCustom04"
            required
          >
            <option :value="1">Pública</option>
            <option :value="0">Privada</option>
          </select>
        </div>

        <div class="col-md-3 mb-3">
          <label for="validationCustom03">Data da coleta</label>
          <input
            :disabled="!model.examined"
            name="data"
            type="date"
            class="form-control"
            id="validationCustom03"
            v-model="model.collected_at"
            required
          />
          <div class="valid-feedback">Looks good!</div>
        </div>
        <div class="col-md-1 mb-3">
          <label for="validationCustom03">Recebeu exames?</label>
          <select
            v-model="model.examined"
            class="custom-select"
            id="validationCustom04"
            required
          >
            <option :value="true">Sim</option>
            <option :value="false">Não</option>
          </select>
        </div>
        <div class="col-md-4 mb-3">
          <label for="validationCustom03">Data do resultado</label>
          <input
            :disabled="!model.examined"
            name="data"
            type="date"
            class="form-control"
            id="validationCustom03"
            v-model="model.collected_at"
            required
          />
          <div class="valid-feedback">Looks good!</div>
        </div>
      </div>
    </form>

    <button class="btn btn-primary" type="submit">Submit form</button>
  </section>
</template>
<script>
import Multiselect from "vue-multiselect";
import Service from "../services";
export default {
  components: { Multiselect },
  data() {
    return {
      notFind: false,
      interned: ["Internado", "Isolado"],
      loading: false,
      reporting_unity: [
        { label: "Atenção Primária", value: "0" },
        { label: "Hospital Público", value: "1" },
        { label: "Laboratório Privado", value: "2" }
      ],
      pregnant: [
        {
          label: "Sim",
          value: 1
        },
        { label: "Não", value: 0 }
      ],
      autocomplete: "",
      district: "",
      districts: [],
      incident_type: [
        { label: "Confirmado", value: "confirmed" },
        { label: "Suspeito", value: "suspicious" },
        { label: "Curado", value: "recovered" },
        { label: "Descartado", value: "discarded" },
        { label: "Óbito", value: "lethal" }
      ],
      cities: [],
      currentPlace: null,
      currentPlaceDistrict: null,
      modelDistrict: {
        name: "",
        latitude: undefined,
        longitude: undefined,
        city_id: undefined
      },
      model: {
        name: "",
        created_at: this.currentDate(),
        gender: "",
        pregnant: "",
        birth: "",
        district_id: undefined,
        street: "",
        contact: "",
        latitude: "",
        longitude: "",
        onset_at: "",
        incident_type: "suspicious",
        reporting_unity: "0",
        isolated: "",
        result_at: "",
        discharged_at: "",
        observations: "",
        interned: "Isolado",
        interned_at: "",
        user_id: "",
        city_id: null,
        public_clinic: 0,
        collected_at: "",
        accepted: false,
        symptoms: [],
        morbities: [],
        examined: false,
        received: false
      },
      symptoms: [],
      morbities: []
    };
  },
  async mounted() {
    const local = JSON.parse(localStorage.getItem("user"));
    this.model.user_id = local.id;

    const request = new Service();
    request.get("incident/symptoms").then(res => {
      this.symptoms = res;
    });
    request.get("incident/morbities").then(res => {
      this.morbities = res;
    });
    request.get("city").then(res => {
      this.cities = res.data.map(e => {
        return { name: e.name, id: e.id };
      });
    });
    if (this.$route.params.id) {
      let response = await request.get(`incident/${this.$route.params.id}}`);
      response.city_id = response.city ? response.city[0].id : undefined;
      response.created_at = response.created_at.slice(0, 10);
      response.received =
        response.examined == "1" && response.result_at != null ? true : false;
      response.examined = response.examined == "1" ? true : false;
      response.interned = response.interned === 1 ? "Internado" : "Isolado";
      response.user_id = local.id;
      this.autocomplete = `${response.street}`;
      //  - ${response.district}, ${response.city[0].name}`;
      delete response.city;
      this.model = response;
    }
  },
  methods: {
    getDistrict() {
      const request = new Service();
      request.get(`city/districts/${this.model.city_id}`).then(res => {
        this.districts = res;
      });
    },
    closeModal() {
      this.$bvModal.hide("modal-search-map");
    },
    pressOk() {
      let aux = {
        name: "",
        latitude: undefined,
        longitude: undefined,
        city_id: undefined
      };
      if (this.modelDistrict.latitude == undefined) {
        aux = {
          name: this.district,
          latitude: "0",
          longitude: "0",
          city_id: this.model.city_id
        };
      } else {
        aux = {
          name: this.district,
          latitude: this.modelDistrict.latitude,
          longitude: this.modelDistrict.longitude,
          city_id: this.model.city_id
        };
      }
      if (this.district != "") {
        const request = new Service();
        request.post("city/districts", aux).then(() => {
          this.getDistrict();
          this.district = "";
          this.modelDistrict = {
            name: "",
            latitude: undefined,
            longitude: undefined,
            city_id: undefined
          };
        });
      }
    },
    setPlaceDistrict(place) {
      this.currentPlaceDistrict = place;

      let [Bairro] = place.formatted_address.split(",", 4);

      let obj = {
        name: Bairro,
        latitude: place.geometry.location.lat(),
        longitude: place.geometry.location.lng(),
        city_id: this.model.city_id
      };

      this.modelDistrict = obj;
    },
    setPlace(place) {
      this.currentPlace = place;

      setTimeout(() => {
        if (Object.keys(this.currentPlace).length > 1) {
          this.autocomplete = place.formatted_address;
          let [rua, numero] = place.name.split(",", 2);
          if (!this.notFind) this.model.street = `${rua},${numero}`;

          this.model.latitude = place.geometry.location.lat();
          this.model.longitude = place.geometry.location.lng();
        } else {
          this.notFind = true;
          this.model.street = this.currentPlace.name;
          this.model.latitude = 0;
          this.model.longitude = 0;

          this.$refs.iterned.$el.focus();
          // this.$toast.open({
          //   position: "top",
          //   message:
          //     "Não foi Possivel encontrar o endereço, clique novamente no campo endereço e selecione por bairro e cidade",
          //   type: "warning",
          //   all other options
          // });
          // console.log(this.model.district_id);
          // let res = this.districts.filter(
          //   (e) => e.id === this.model.district_id
          // )[0];
          // let finder = this.cities.filter(
          //   (e) => e.id === this.model.city_id
          // )[0];
          // this.autocomplete = `${res.name} ${finder.name}`;
          // this.model.street = this.currentPlace.name;
        }
      }, 1500);
    },
    currentDate() {
      const now = new Date(Date.now());
      return `${now.getUTCFullYear()}-${("0" + (now.getUTCMonth() + 1)).substr(
        -2
      )}-${("0" + now.getDate()).substr(-2)}`;
    },
    async save() {
      "use strict";
      window.addEventListener(
        "load",
        function() {
          // Fetch all the forms we want to apply custom Bootstrap validation styles to
          var forms = document.getElementsByClassName("needs-validation");
          // Loop over them and prevent submission
          //var validation = Comentado para deploy
          Array.prototype.filter.call(forms, function(form) {
            form.addEventListener(
              "submit",
              function(event) {
                if (form.checkValidity() === false) {
                  event.preventDefault();
                  event.stopPropagation();
                }
                form.classList.add("was-validated");
              },
              false
            );
          });
          //console.log(validation); Comentando para deploy
        },
        false
      );
    }
  },
  watch: {
    "model.gender": function() {
      if (this.model.gender == "M") {
        this.model.pregnant = 0;
      }
    },
    "model.city_id": function() {
      this.getDistrict();
    },
    "model.interned": function() {
      if (this.model.interned == "Internado") {
        this.model.isolated = 0;
      } else {
        this.model.interned_at = "";
        this.model.isolated = 1;
      }
    }
  }
};
</script>
<style>
.pac-container {
  z-index: 100000 !important;
}
.pac-logo {
  z-index: 100000 !important;
}
h4 {
  text-transform: uppercase;
}
.componentGoogle {
  width: 100%;
  border: 1px solid #999;
  /* height: 100%; */
  padding: 6px 0 6px 2px;
  border-radius: 3px;
}
</style>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
